import React, { FunctionComponent } from 'react';

export const FullScreenLoader: FunctionComponent = () => {
  return (
    <div className={`d-full mt-32 flex justify-center`}>
      <svg
        width="414"
        height="608"
        viewBox="-2 -2 32 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={`animated-path`}
          fill={`transparent`}
          strokeWidth={1}
          stroke={`black`}
          d="M15.0495 34.0001C11.7759 34.0001 9.24159 32.4364 7.99481 31.4764C7.16779 30.8416 7.02056 29.6619 7.66275 28.8444C8.30493 28.0269 9.49847 27.8813 10.3223 28.5161C11.2151 29.2036 13.0571 30.3431 15.3283 30.244C19.1689 30.0768 22.7996 26.5282 22.7996 22.9393C22.7996 21.1495 22.4644 19.9635 21.5622 18.5701L21.5278 18.5143C19.786 15.6005 16.0112 13.6404 12.1361 13.6404C11.1776 13.6404 10.2879 13.7642 9.48907 14.012C8.48976 14.3216 7.4278 13.7704 7.11454 12.7826C6.80127 11.7948 7.35888 10.742 8.35819 10.4354C9.52353 10.0763 10.7922 9.89355 12.133 9.89355C17.3708 9.89355 22.3266 12.5163 24.77 16.579C26.0575 18.5824 26.5838 20.428 26.5838 22.9393C26.5838 28.4728 21.2928 33.74 15.4849 33.9908C15.3346 33.997 15.1873 34.0001 15.0401 34.0001H15.0495Z"
        />
        <path
          className={`animated-path`}
          fill={`transparent`}
          strokeWidth={1}
          stroke={`black`}
          d="M6.81973 26.2184C2.93214 26.2184 0 23.3727 0 19.6011C0 18.1736 0.523149 16.7244 1.55692 15.2938L1.59451 15.2442C1.71668 15.0708 1.85138 14.9005 1.99548 14.7302C2.09573 14.6095 2.42152 14.2224 2.94467 13.7207C3.6965 12.9993 4.8963 13.0178 5.6262 13.761C6.3561 14.5042 6.33731 15.6901 5.58548 16.4116C5.17197 16.808 4.94015 17.0929 4.93702 17.096L4.89316 17.1486C4.82111 17.2291 4.75846 17.3096 4.70521 17.3871L4.64255 17.4769C4.43267 17.7679 3.79048 18.6535 3.79048 19.6042C3.79048 21.2918 5.03413 22.4716 6.81973 22.4716C8.80582 22.4716 12.6339 21.9328 15.9451 18.3191L15.967 18.2974L16.5183 17.7153C17.2326 16.9597 18.4324 16.9195 19.1999 17.6286C19.9642 18.3346 20.005 19.5206 19.2876 20.2792L18.7488 20.849C14.4602 25.5217 9.43548 26.2215 6.82286 26.2215L6.81973 26.2184Z"
        />
        <path
          stroke={`black`}
          strokeWidth={1}
          className={`animated-path`}
          fill={`transparent`}
          d="M9.12705 20.0439C8.19979 20.0439 7.38844 19.372 7.25373 18.4368C6.76818 15.1018 6.60841 12.0703 6.76504 9.16576C6.79324 4.10601 10.9659 0 16.0877 0C21.2096 0 25.4136 4.13388 25.4136 9.2184C25.4136 12.1849 24.5678 13.9375 23.2019 15.8047C22.5879 16.6439 21.4007 16.8297 20.5549 16.2228C19.7059 15.6158 19.518 14.4454 20.132 13.6062C21.1438 12.2251 21.6231 11.2157 21.6231 9.2184C21.6231 6.20237 19.1421 3.74991 16.0909 3.74991C13.0397 3.74991 10.5587 6.20237 10.5587 9.2184V9.32058C10.4083 11.9898 10.5555 14.7953 11.0066 17.9011C11.157 18.9261 10.4365 19.8767 9.39958 20.0253C9.30874 20.0377 9.21789 20.0439 9.12705 20.0439Z"
        />
      </svg>
    </div>
  );
};
